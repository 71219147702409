<script>
import Vue from 'vue'

export default Vue.extend({
    name: 'NotFound',

    props: {
        instanceName: {
            type: String,
            required: true,
        },
    },
})
</script>

<template>
    <b-jumbotron header="404 Not Found" lead="This page does not exist" bg-variant="white" align="center" class="user-select-none">
        <span>
            Try going <router-link :to="{ name: 'appHome', props: { instanceName } }">back home</router-link> or to the
            <a href="" @click.prevent="$router.go(-1)">previous page</a>
        </span>
    </b-jumbotron>
</template>
